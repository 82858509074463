<script setup>
import {ref, computed, watch, onMounted, onBeforeUnmount, nextTick} from 'vue';
import {onClickOutside} from '@vueuse/core';
import {createToaster} from "@meforma/vue-toaster";
import {email, helpers, minLength, required, requiredIf} from "@vuelidate/validators";

import arrowBack from "../../../assets/images/arrow-back.svg";
import arrowDown from "../../../assets/images/arrow-down.svg";
import {useRoute, useRouter} from "vue-router";
import {useStore} from "../../../stores/index";
import DogsService from "../../../services/dogs.service";
import CensusDog from "./details/CensusDog.vue";
import CensusHolding from "./details/CensusHolding.vue";
import CensusMaster from "../../../views/pages/census/details/CensusMaster.vue";
import PeopleService from "../../../services/people.service";
import useVuelidate from "@vuelidate/core";
import SynchronizerCensusHelper from "../../../helpers/synchronizerCensusHelper";
import HoldingService from "../../../services/holding.service";
import RecensementService from "../../../services/recensement.service";
import {
  getBody,
  getFatherBody,
  getMotherBody,
  getOwnerBody,
  formatBody, getFullMasterBody,
} from "../../../components/census/DetailCensus/Dog/handle-datas-dog/formatDatasBodies";
import {
  handleSubmitDog,
  handleSubmitOwnerMaster
} from "../../../components/census/DetailCensus/Dog/handle-datas-dog/dogFormSubmit";
import cacheHelper from "../../../helpers/cacheHelper";
import {
  getOwnerForm
} from "../../../components/census/DetailCensus/Dog/handle-datas-dog/formatDatasForm";
import CensusService from "../../../services/census.service";
import useDog from "../../../compositions/useDog"

const props = defineProps({
  censusId: String,
  dogId: String
});

const { getDogForm } = useDog()

const router = useRouter();
const route = useRoute();
const store = useStore();
const toaster = createToaster({position: "bottom"});

const masterRef = ref(null);
const loaded = ref(false);
const leaving = ref(false);
const error = ref(null);
const loading = ref(false);
const census = ref(null);
const holding = ref(null);
const dog = ref(null);
const technicalFollowUp = ref(false);
const dogMasterEntries = ref([]);
const dogMasterList = ref([]);
const forms = ref({});
const dogForm = ref({});
const dogFatherForm = ref({});
const dogMotherForm = ref({});
const holdingForm = ref({});
const masterForm = ref({});
const ownerForm = ref({});
const navigationOpen = ref(false);
const currentTab = ref(route.query.tab ?? 'dog');
const controller = ref(new AbortController());
const navigationRef = ref(null);
const dogDataToApplyToOthers = ref(null);
const specNbPersons = ref({});
const specieOther = ref(null);
const noFatherInfo = ref(null);
const noMotherInfo = ref(null);
const masterIsOwnerDog = ref(false)
const ownerIsOwnerExploitation = ref(false)
const masterIsOwnerExploitation = ref(false)
const masterId = ref(-1)
const ownerId = ref(-1)
const ownerSocialReason = ref("particular")
const masterSocialReason = ref("particular")
const showLaunchCensusModal = ref(false)

onClickOutside(navigationRef, () => {
  navigationOpen.value = false;
})

const isOffline = computed(() => !store.getIsOnline);
const currentUser = computed(() => store.getUser);
const isUserAdmin = computed(() => store.userIsAdmin);
const isSameUser = computed(() => {
  return census.value
      && census.value.person
      && census.value.person.id === currentUser.value.id
      ;
});
const readOnly = computed(() => !(isUserAdmin.value || (census.value?.status === 'scheduled' && isSameUser.value) || isSameUser.value));
const disabled = computed(() => census.value && census.value.status === 'completed' && !isUserAdmin.value);
const allMaster = computed(() => census.value?.dogs?.filter(el => !!el.master).map(el => el.master) ?? []);
const masters = ref([]);
const dogRules = computed(() => {
  return {
    name: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    gender: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    birthdate: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    identificationType: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    identificationNum: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    appearance: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    numLof: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.appearance === 'pure_lof';
      })),
    },
    breed: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return (dogForm.value.appearance === 'standard' || dogForm.value.appearance === 'cross' || dogForm.value.appearance === 'pure_lof');
      })),
    },
    isIdentified: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    specie: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    typeOfSystem: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    typeOfDriving: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return (dogForm.value.typeOfSystem !== 'indoor');
      })),
    },
    humainPresence: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherDogsPresence: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    oustiderHumanPresence: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    typeOfPredation: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherTypeOfPredation: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.typeOfPredation && dogForm.value.typeOfPredation.includes('other');
      })),
    },
    isOtherSpecie: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherSpecie: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isOtherSpecie;
      })),
    },
    otherTypeOfSystem: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isOtherSpecie;
      })),
    },
    otherTypeOfDriving: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isOtherSpecie && dogForm.value.otherTypeOfSystem !== 'indoor';
      })),
    },
    isBornHerd: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    beforeTwoMonth: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isFirstForBreeder: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isHealthy: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAdaptedMorpho: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAdaptedSize: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedDemarche: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedAplombs: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedBackLine: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedJaw: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    isAdaptedPigmentation: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    hasTesticleConcerned: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    adaptedMorphoCommentary: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.isAdaptedMorpho === 'false';
      })),
    },
    hasHealthyTrouble: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    otherProblem: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.hasHealthyTrouble;
      })),
    },
    hipDysplasia: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    elbowDysplasia: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    exploitationAge: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    introductionCondition: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    introductionConditionPuppies: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.introductionCondition === 'other_puppies';
      })),
    },
    introductionConditionAdults: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.introductionCondition === 'with_adults';
      })),
    },
    parcel: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    parcelHeifers: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    parcelOthers: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value?.parcelHeifers?.includes('others');
      })),
    },
    behaviorWithHuman: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    temper: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    respectingOthers: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    startRespectingOthers: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.respectingOthers === 'little' || dogForm.value.respectingOthers === 'a_lot';
      })),
    },
    endRespectingOthers: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(function () {
        return dogForm.value.respectingOthers === 'little' || dogForm.value.respectingOthers === 'a_lot';
      })),
    },
    tookTime: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isEasyDog: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    characteristics: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isManipulable: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    unknownDogTemper: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isMunipulableCensusTaker: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    unknownTrustWithOthers: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    unknownTrustOthersWithoutYou: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    positionInGroup: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    canWorkInGroup: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    canWorkAlone: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAgressifToOtherDogs: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    presentHerd: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    watchfulAwakening: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasExcessiveBarking: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isGoodProtector: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    distanceHerd: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    fenceRespect: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    runAfterVehicles: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isAlwaysUsable: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    recovery: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    wantPuppy: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    isPotentialBreeder: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasGoodImpregnateHerdBfrTwoMounth: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => technicalFollowUp.value)),
    },
    hasGoodFamiliarizationHumansBfrTwoMounths: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => technicalFollowUp.value)),
    },
    hasGoodFamiliarizationHumansAfterTwoMounths: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => technicalFollowUp.value)),
    },
    wasEasySetupPuppy: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => technicalFollowUp.value)),
    },
    isClingyWithHerd: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => technicalFollowUp.value)),
    },


  };
});
const dogFatherRules = computed(() => {
  return {
    email: {
      required: helpers.withMessage(
          "Le champ 'Email' ou 'N° de téléphone' est requis !",
          requiredIf(
              () => dogFatherForm.value?.ownerName && !dogFatherForm.value?.phone
          )
      ),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    phone: {
      required: helpers.withMessage(
          "Le champ 'Email' ou 'N° de téléphone' est requis !",
          requiredIf(
              () => dogFatherForm.value?.ownerName && !dogFatherForm.value?.email
          )
      ),
      minLength: helpers.withMessage("Le champ n'est pas au format téléphone !", minLength(10)),
    },
  };
});
const dogMotherRules = computed(() => {
  return {
    email: {
      required: helpers.withMessage("Le champ 'Email' ou 'N° de téléphone' est requis !", requiredIf(function () {
        return !dogMotherForm.value.phone && dogMotherForm.value.ownerName;
      })),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    phone: {
      required: helpers.withMessage("Le champ 'Email' ou 'N° de téléphone' est requis !", requiredIf(function () {
        return !dogMotherForm.value.email && dogMotherForm.value.ownerName;
      })),
      minLength: helpers.withMessage("Le champ n'est pas au format téléphone !", minLength(10)),
    },
  };
});
const masterRules = computed(() => {
  return {
    email: {
      required: helpers.withMessage("Le champ est requis !", required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    zipCode: {
      required: helpers.withMessage("Le champ est requis !", required),
      minLength: helpers.withMessage("Le code postal doit être de 5 chiffres", minLength(5)),
    },
    usedCp: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    yearRegister: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasGrownPuppy: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasCpFormation: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasTechnicalFormation: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    profileMatch: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    puppyGrowth: {
      required: helpers.withMessage("Le champ est requis !", required),
    },
    hasRegularVisit: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => masterForm.value.profileMatch)),
    },
    breedChart: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => masterForm.value.profileMatch)),
    },
    couplings: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => masterForm.value.profileMatch)),
    },
    hasSelfFormation: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => masterForm.value.profileMatch)),
    },
    batchCaring: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => masterForm.value.profileMatch)),
    },
    brokenDogs: {
      required: helpers.withMessage("Le champ est requis !", requiredIf(() => masterForm.value.profileMatch)),
    },
  };
});
const ownerRules = computed(() => {
  return {
    email: {
      required: helpers.withMessage("Le champ est requis !", required),
      email: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
    zipCode: {
      required: helpers.withMessage("Le champ est requis !", required),
      minLength: helpers.withMessage("Le code postal doit être de 5 chiffres", minLength(5)),
    },
  };
});
const holdingRules = computed(() => {
  return {
    mail: {
      required: helpers.withMessage("Le champ est requis !", required),
      mail: helpers.withMessage("Le champ n'est pas du format email !", email),
    },
  };
});
const todayTime = (new Date()).getTime();
const birthDateTime = computed(() => {
  return dog.value?.birthdate ? (new Date(dog.value?.birthdate)).getTime() : 0;
})
const monthTime = (new Date(1000 * 60 * 60 * 24 * 30 * 18)).getTime();
const isDogAboveEighteen = computed(() => todayTime - birthDateTime.value > monthTime)

const dogValidator = useVuelidate(dogRules, dogForm);
const dogFatherValidator = useVuelidate(dogFatherRules, dogFatherForm);
const dogMotherValidator = useVuelidate(dogMotherRules, dogMotherForm);
const masterValidator = useVuelidate(masterRules, masterForm);
const ownerValidator = useVuelidate(ownerRules, ownerForm);
const holdingValidator = useVuelidate(holdingRules, holdingForm);

const handleBack = () => {
  router.push({path: `/census/${props.censusId}/dogs`})
}
const handleDogClick = (dogId) => {
  router.push({
    name: 'census_details_page',
    params: {
      censusId: props.censusId,
      dogId: dogId
    },
    query: {
      tab: currentTab.value
    }
  })
  navigationOpen.value = false
}
const handleCurrentTabChange = (tab) => {
  currentTab.value = tab;

  router.push({
    name: 'census_details_page',
    params: {
      censusId: props.censusId,
      dogId: props.dogId
    },
    query: {
      tab
    }
  });
}
const fetchCensus = async () => {
  try {
    census.value = await CensusService.getCensus(props.censusId, controller, !isOffline.value);
    if (census.value?.dogs.length === 0) {
      await router.push(`/census/${props.censusId}/dogs`);
      return;
    }
    store.setCensusData(census.value);
    masters.value = (Array.from(new Set(allMaster.value.map(el => el.id))).map(
        id => allMaster.value.find(el => el.id === id)
    ));
  } catch (e) {
    if (e.response && e.response.status === 404) {
      error.value = "Le recensement est introuvable !";
    } else {
      error.value =
          "Une erreur inattendue est survenue, veuillez contacter un administrateur !";
    }
  }
}
const fetchHolding = async () => {
  if (isOffline.value) {
    holding.value = census.value.exploitation;
  } else {
    holding.value = await HoldingService.getHoldingById(census.value.exploitation.id, controller);
  }

  holdingForm.value = getHoldingForm();
  forms.value = {
    ...forms.value,
    holding: {...holdingForm.value}
  }
}
const fetchDog = async () => {
  try {
    dog.value = isOffline.value
        ? (await DogsService.getById(props.dogId, controller, props.censusId)) ?? census.value.dogs.find(el => el.id === props.dogId)
        : await DogsService.getById(props.dogId, controller);
    store.setCorrectDog(dog.value);

    if (dog.value?.nbPersons && isNaN(dog.value.nbPersons)) {
      specNbPersons.value = dog.value.nbPersons ? JSON.parse(dog.value.nbPersons) : {};
    }
    if (dog.value?.specie?.length > 0) {
      specieOther.value = dog.value.specie?.split("##")[1];
    }

    dogForm.value = getDogForm(dog.value);

    technicalFollowUp.value = !!(
        dogForm.value.hasGoodImpregnateHerdBfrTwoMounth !== null
        || dogForm.value.hasGoodFamiliarizationHumansBfrTwoMounths !== null
        || dogForm.value.hasGoodFamiliarizationHumansAfterTwoMounths !== null
        || dogForm.value.wasEasySetupPuppy !== null
        || dogForm.value.isClingyWithHerd !== null
    );

    dogFatherForm.value = getDogFatherForm();
    dogMotherForm.value = getDogMotherForm();

    forms.value = {
      ...forms.value,
      dog: {...dogForm.value},
      father: {...dogFatherForm.value},
      mother: {...dogMotherForm.value},
    }

    noFatherInfo.value = !dog.value?.father;
    noMotherInfo.value = !dog.value?.mother;
  } catch (e) {
    toaster.error('Une erreur est survenue');
  }
}
const getMasterFormFromPayload = (payload) => {
  return payload ? {
    firstname: payload.firstname,
    lastname: payload.lastname,
    companyname: payload.companyname,
    address: payload.address,
    zipCode: payload.zipCode,
    city: payload.city,
    email: payload.email,
    phoneNumber: payload.phoneNumber,
    yearRegister: payload.yearRegister,
    hasCpFormation: payload.hasCpFormation,
    hasTechnicalFormation: payload.hasTechnicalFormation,
    isJoiningProductionNetwork: payload.isJoiningProductionNetwork,
    profileMatch: payload.profileMatch,
    hasRegularVisit: payload.hasRegularVisit,
    breedChart: payload.breedChart,
    hasSelfFormation: payload.hasSelfFormation,
    batchCaring: payload.batchCaring,
    brokenDogs: payload.brokenDogs,
    puppyGrowth: payload.puppyGrowth,
    usedCp: payload.usedCp,
    notes: payload.notes,
    couplings: payload.couplings,
    hasGrownPuppy: payload.hasGrownPuppy,
  } : {
    firstname: "",
    lastname: "",
    companyname: null,
    address: "",
    zipCode: "",
    city: "",
    email: "",
    phoneNumber: "",
    yearRegister: "",
    hasCpFormation: "",
    hasTechnicalFormation: "",
    isJoiningProductionNetwork: null,
    profileMatch: null,
    hasRegularVisit: "",
    breedChart: null,
    hasSelfFormation: null,
    batchCaring: "",
    brokenDogs: "",
    puppyGrowth: "",
    usedCp: "",
    notes: "",
    couplings: null,
    hasGrownPuppy: null,
  }
}
const getOwnerFormFromPayload = (payload) => {
  return payload ? {
    companyname: payload.companyname,
    firstname: payload.firstname,
    lastname: payload.lastname,
    address: payload.address,
    city: payload.city,
    zipCode: payload.zipCode,
    email: payload.email,
    phoneNumber: payload.phoneNumber,
  } : {
    companyname: "",
    firstname: "",
    lastname: "",
    address: "",
    city: "",
    zipCode: "",
    email: "",
    phoneNumber: "",
  }
}
const fetchMaster = async () => {
  masterId.value = dog.value.master?.id ?? -1;
  ownerId.value = dog.value.owner?.id ?? -1;

  if (isOffline.value) {
    let cacheDogMaster = cacheHelper.getDogMasterList()
    dogMasterEntries.value = cacheDogMaster.entries
    dogMasterList.value = cacheDogMaster.list
  } else {
    const response = await PeopleService.getFilteredByExploitationId(
        census.value.exploitation.id,
        1,
        `&pagination=false`,
        controller
    );
    dogMasterList.value = response["hydra:member"];
    if (props?.exploitation?.owner && !dogMasterList.value?.some((master) => master.id === props?.exploitation?.owner?.id)) {
      dogMasterList.value.push(props?.exploitation?.owner)
    }
    dogMasterEntries.value = [
      {value: -1, label: "Nouvelle entrée"},
      ...dogMasterList.value.map((dogMaster) => {
        return {
          value: dogMaster.id,
          label: dogMaster.firstname
              ? `${dogMaster.firstname} ${dogMaster.lastname}`
              : `${dogMaster.companyname}`,
        };
      }),
    ];
    localStorage.setItem("dogMaster", JSON.stringify({
      entries: dogMasterEntries.value.filter((ent) => ent.value !== -1),
      list: dogMasterList.value
    }))
  }

  changeOwner(ownerId.value)
  changeMaster(masterId.value)
  ownerForm.value = getOwnerForm(dog, census.value.exploitation.id);

  forms.value = {
    ...forms.value,
    owner: {...ownerForm.value},
  }

  ownerIsOwnerExploitation.value = dog.value.owner?.id === census.value.exploitation?.owner?.id
  ownerSocialReason.value = dog.value.owner?.companyname ? "social" : "particular";
}
const resetAll = () => {
  error.value = null;
  store.leavingCensusDetail = false;
  store.quittingCensusDetails = false;
  store.quittingCensusDetailsTo = null;
  loaded.value = false;
  leaving.value = false;
  error.value = null;
  loading.value = false;
  census.value = null;
  holding.value = null;
  dog.value = null;
  dogForm.value = {};
  dogFatherForm.value = {};
  dogMotherForm.value = {};
  holdingForm.value = {};
  masterForm.value = {};
  ownerForm.value = {};
  forms.value = {}
}
const fetchAll = async () => {
  await fetchCensus();
  if (!census.value.dogs.find(el => el.id === parseInt(props.dogId))) {
    toaster.error("Ce chien n'appartient pas à ce recensement");
    await router.push(`/census/${props.censusId}/dogs`);
    return;
  }

  await fetchDog();
  await fetchMaster();
  await fetchHolding();
}
const submitDog = async (_callBack) => {
  if (readOnly.value) {
    return;
  }

  const dogFatherBody = getFatherBody(dogFatherForm.value);
  const dogMotherBody = getMotherBody(dogMotherForm.value);

  const body = getBody(
      dogForm.value,
      dogFatherForm.value,
      dogFatherBody,
      dogMotherForm.value,
      dogMotherBody,
      {
        specieOther: specieOther.value,
        specNbPersons: specNbPersons.value,
        noFatherInfo: noFatherInfo.value,
        noMotherInfo: noMotherInfo.value,
      }
  );

  applyDogToOthers();

  if (isOffline.value) {
    const syncService = new SynchronizerCensusHelper(props.censusId);
    syncService.updateDog(dog.value.id, {
      correctDog: JSON.parse(JSON.stringify(dog.value)),
      body: JSON.parse(JSON.stringify(body)),
    });
    syncService.persistInCache();
    store.correctDog = dog.value
    toaster.info("Données mises en cache, prêtes à être synchronisées");

    forms.value = {
      ...forms.value,
      dog: {...dogForm.value},
      father: {...dogFatherForm.value},
      mother: {...dogMotherForm.value},
    }

    return;
  }

  try {
    if (dogDataToApplyToOthers.value) {
      const otherDogs = census.value.dogs.filter(
          (d) => d.id !== dog.value.id
      );

      await Promise.all(
          otherDogs.map((otherDog) => {
            const newDatas = formatBody(
                {
                  ...otherDog,
                  ...dogDataToApplyToOthers,
                },
                specNbPersons.value
            );

            DogsService.update(otherDog.id, newDatas);
          })
      );
    }

    dog.value = await handleSubmitDog(
        dog,
        body,
        props.censusId,
    );

    forms.value = {
      ...forms.value,
      dog: {...dogForm.value},
      father: {...dogFatherForm.value},
      mother: {...dogMotherForm.value},
    }

    if (_callBack) {
      _callBack()
      return;
    }

    leaving.value !== true && toaster.success("Formulaire correctement enregistré");
  } catch (e) {
    leaving.value !== true && toaster.error("Le formulaire n'est pas valide");
  }
}
const submitMaster = async () => {
  if (readOnly.value) {
    return;
  }

  const ownerBody = getOwnerBody(ownerForm.value, census.value.exploitation.id);
  const masterBody = getFullMasterBody(masterForm.value, census.value.exploitation.id);

  if (isOffline.value) {
    const syncService = new SynchronizerCensusHelper(props.censusId);
    syncService.updateDogOwnerMaster(dog.value.id, {
      correctDog: JSON.parse(JSON.stringify(dog.value)),
      masterBody: JSON.parse(JSON.stringify(masterBody)),
      ownerBody: JSON.parse(JSON.stringify(ownerBody)),
      exploitation: JSON.parse(JSON.stringify(census.value.exploitation)),
      ownerId: ownerId.value ?? -1,
      masterId: masterId.value ?? -1,
      masterIsOwnerExploitation: masterIsOwnerExploitation.value,
      ownerIsOwnerExploitation: ownerIsOwnerExploitation.value,
      masterIsOwnerDog: masterIsOwnerDog.value,
    });
    syncService.persistInCache();
    await fetchAll();
    toaster.info("Données mises en cache, prêtes à être synchronisées");
  } else {
    try {
      let updateReturn = await handleSubmitOwnerMaster(
          dog,
          masterBody,
          ownerBody,
          census.value.exploitation,
          ownerId.value,
          masterId.value,
          masterIsOwnerExploitation.value,
          ownerIsOwnerExploitation.value,
          masterIsOwnerDog.value
      );
      if (updateReturn === 'error') {
        leaving.value !== true && toaster.error("Le formulaire n'est pas valide");
      } else {
        leaving.value !== true &&
        toaster.success("Formulaire correctement enregistré");
      }
    } catch (e) {
      leaving.value !== true && toaster.error("Le formulaire n'est pas valide");
    }
  }

  forms.value = {
    ...forms.value,
    master: {...masterForm.value},
    owner: {...ownerForm.value},
  }
}
const submitHolding = async () => {
  if (readOnly.value) {
    return;
  }

  try {
    if (isOffline.value) {
      const syncService = new SynchronizerCensusHelper(census.value.id);
      syncService.updateHolding(census.value.exploitation.id, holdingForm.value, census.value.exploitation.owner);
      syncService.persistInCache();
      toaster.info("Données mises en cache, prêtes à être synchronisées");

      forms.value = {
        ...forms.value,
        holding: {...holdingForm.value},
      }
      return;
    }

    await HoldingService.updateHolding(census.value.exploitation.id, holdingForm.value, controller);
    await RecensementService.upgradeIfComplete(census.value.id, controller);
    leaving.value !== true && toaster.success("Formulaire correctement enregistré");

    forms.value = {
      ...forms.value,
      holding: {...holdingForm.value},
    }
  } catch (e) {
    toaster.error("Une erreur est survenue")
  }
}
const submit = async () => {
  if (currentTab.value === 'dog') {
    await submitDog();
  }

  if (currentTab.value === 'master') {
    await submitMaster();
  }

  if (currentTab.value === 'holding') {
    await submitHolding();
  }
}
const submitAll = async () => {
  await submitHolding();
  await submitDog();
  await submitMaster();
}
const isCensusReady = () => {
  const isParentCompleted = (parent) => {
    return parent.ownerName ? !!(parent.email || parent.phone) : true;
  }

  const otherDogs = census.value.dogs.filter(
      (d) => d.id !== dog.value.id
  );

  return otherDogs.every(d => {
    if (
        d.name !== null &&
        d.gender !== null &&
        d.birthdate !== null &&
        d.identificationType !== null &&
        d.identificationNum !== null &&
        (
            (d.appearance === 'pure_lof' && d.numLof !== null && d.breed !== null) ||
            ((d.appearance === 'standard' || d.appearance === 'cross') && d.breed !== null) ||
            (d.appearance === 'undetermined')
        )
        &&
        d.isIdentified !== null &&
        d.specie !== null &&
        (
            d.typeOfSystem === 'indoor' ||
            (d.typeOfSystem !== 'indoor' && d.typeOfDriving !== null)
        ) &&
        d.humainPresence !== null &&
        d.otherDogsPresence !== null &&
        d.oustiderHumanPresence !== null &&
        (
            (d.typeOfPredation.includes('other') && d.otherTypeOfPredation !== null) ||
            d.typeOfPredation !== null
        ) && ((d.isOtherSpecie !== null) || null || null) && d.isBornHerd !== null && d.beforeTwoMonth !== null && d.isFirstForBreeder !== null && d.isHealthy !== null && (
            (d.isAdaptedMorpho !== null && d.isAdaptedMorpho !== "false") ||
            (d.isAdaptedMorpho == "false" &&
                d.isAdaptedSize !== null &&
                d.isAdaptedDemarche !== null &&
                d.isAdaptedAplombs !== null &&
                d.isAdaptedBackLine !== null &&
                d.isAdaptedJaw !== null &&
                d.isAdaptedPigmentation !== null &&
                d.hasTesticleConcerned !== null &&
                d.adaptedMorphoCommentary !== null
            )
        ) && (
            (d.hasHealthyTrouble && d.otherProblem) ||
            (d.hasHealthyTrouble === false)
        ) && d.hipDysplasia !== null && d.elbowDysplasia !== null && d.exploitationAge !== null && (
            (d.introductionCondition === 'other_puppies' && d.introductionConditionPuppies !== null) ||
            (d.introductionCondition === 'with_adults' && d.introductionConditionAdults !== null) ||
            (d.introductionCondition === 'alone')
        ) && d.parcel !== null && d.parcelHeifers !== null && (
            (d.parcelHeifers.includes('others') && d.parcelOthers !== null) ||
            !(d.parcelHeifers.includes('others'))
        ) && d.behaviorWithHuman !== null && d.temper !== null && (
            (d.respectingOthers === "yes") ||
            ((d.respectingOthers === 'a_lot' || d.respectingOthers === 'little') && d.startRespectingOthers !== null && d.endRespectingOthers !== null)
        ) && d.tookTime !== null && d.isEasyDog !== null && d.characteristics !== null && d.isManipulable !== null && d.unknownDogTemper !== null && d.isMunipulableCensusTaker !== null && d.unknownTrustWithOthers !== null && d.unknownTrustOthersWithoutYou !== null && d.positionInGroup !== null && d.canWorkInGroup !== null && d.canWorkAlone !== null && d.isAgressifToOtherDogs !== null && d.presentHerd !== null && d.watchfulAwakening !== null && d.hasExcessiveBarking !== null && d.isGoodProtector !== null && d.distanceHerd !== null && d.fenceRespect !== null && d.runAfterVehicles !== null && d.isAlwaysUsable !== null && d.recovery !== null && d.wantPuppy !== null && d.isPotentialBreeder !== null && d.hasGoodImpregnateHerdBfrTwoMounth !== null &&
        d.hasGoodFamiliarizationHumansBfrTwoMounths !== null &&
        d.hasGoodFamiliarizationHumansAfterTwoMounths !== null &&
        d.wasEasySetupPuppy !== null &&
        d.isClingyWithHerd !== null
    ) {
      if (d.father && d.mother) {
        return (isParentCompleted(d.father) && isParentCompleted(d.mother))
      } else if (d.father) {
        return isParentCompleted(d.father)
      } else if (d.mother) {
        return isParentCompleted(d.mother)
      } else {
        return true
      }
    } else {
      return false
    }
  })
}

const validate = async () => {
  if (!validateDog()) {
    await router.push({
      name: 'census_details_page',
      params: {
        censusId: props.censusId,
        dogId: props.dogId
      },
      query: {
        tab: 'dog'
      }
    });
    toaster.error(`il manque des informations du chien pour valider le recensement`);

    scrollToFirstInvalidInput();

    return;
  }

  if (!validateMaster()) {
    currentTab.value = 'master';

    toaster.error(`il manque des informations du propriétaire/maître pour valider le recensement`);

    scrollToFirstInvalidInput();
    return;
  }

  if (!validateHolding()) {
    currentTab.value = 'holding';

    toaster.error(`il manque des informations de l'exploitation pour valider le recensement`);

    scrollToFirstInvalidInput();
    return;
  }

  toaster.success(`Le chien est valide`);
}

const validateMaster = () => {
  masterValidator.value.$validate();
  ownerValidator.value.$validate();

  return !(masterValidator.value.$error || ownerValidator.value.$error);
}

const validateHolding = () => {
  holdingValidator.value.$validate();

  return !holdingValidator.value.$error;
}

const validateDog = () => {
  dogValidator.value.$validate()
  dogFatherValidator.value.$validate()
  dogMotherValidator.value.$validate()

  if (dogValidator.value.$error) {
    return false;
  }

  if (dog.value?.mother && dogMotherValidator.value.$error) {
    return false;
  }

  return !(dog.value?.father && dogFatherValidator.value.$error);
}
const leave = async () => {
  const route = store.quittingCensusDetailsTo;

  resetAll();

  await router.push(route);
}
const saveAndLeave = async () => {
  leaving.value = true;
  await submitAll();

  const route = store.quittingCensusDetailsTo;
  resetAll();

  router.push(route);
}
const handleSaveAndQuit = async () => {
  leaving.value = true;
  await submitAll();

  const route = store.quittingCensusDetailsTo;

  resetAll();
  store.currentCensus.step = 0;
  await router.push(route);
}

const getDogFatherForm = () => ({
  name: dog.value?.father?.name,
  identificationNum: dog.value?.father?.identificationNum,
  breed: dog.value?.father?.breed,
  appearance: dog.value?.father?.appearance,
  atWork: dog.value ? dog.value?.father?.atWork : null,
  isCertifiedSonship: dog.value
      ? dog.value?.father?.isCertifiedSonship
      : null,
  numLof: dog.value?.father?.numLof,
  ownerName: dog.value?.father?.ownerName,
  phoneNumber: dog.value?.father?.phoneNumber,
  address: dog.value?.father?.address,
  phone: dog.value?.father?.phone,
  email: dog.value?.father?.email,
});
const getDogMotherForm = () => ({
  name: dog.value?.mother?.name,
  identificationNum: dog.value?.mother?.identificationNum,
  breed: dog.value?.mother?.breed,
  appearance: dog.value?.mother?.appearance,
  atWork: dog.value ? dog.value?.mother?.atWork : null,
  isCertifiedSonship: dog.value
      ? dog.value?.mother?.isCertifiedSonship
      : null,
  numLof: dog.value?.mother?.numLof,
  ownerName: dog.value?.mother?.ownerName,
  phoneNumber: dog.value?.mother?.phoneNumber,
  address: dog.value?.mother?.address,
  phone: dog.value?.mother?.phone,
  email: dog.value?.mother?.email,
});
const getHoldingForm = () => ({
  edeNumber: holding.value?.edeNumber,
  siretNumber: holding.value?.siretNumber,
  name: holding.value?.name,
  zipCode: holding.value?.zipCode,
  address: holding.value?.address,
  city: holding.value?.city,
  mail: holding.value?.mail,
  mobile: holding.value?.mobile,
});
const applyDogToOthers = () => {
  dogDataToApplyToOthers.value = {
    nbPersons: dogForm.value.nbPersons,
    specie: dogForm.value.specie,
    typeOfSystem: dogForm.value.typeOfSystem,
    typeOfDriving: dogForm.value.typeOfDriving,
    humainPresence: dogForm.value.humainPresence,
    otherDogsPresence: dogForm.value.otherDogsPresence,
    oustiderHumanPresence: dogForm.value.oustiderHumanPresence,
    typeOfPredation: dogForm.value.typeOfPredation,
    otherTypeOfPredation: dogForm.value.otherTypeOfPredation,
    isOtherSpecie: dogForm.value.isOtherSpecie,
    otherTypeOfSystem: dogForm.value.otherTypeOfSystem,
    otherSpecie: dogForm.value.otherSpecie,
    otherTypeOfDriving: dogForm.value?.otherTypeOfDriving,
    otherStartAtPeriodHousing: dogForm.value.otherStartAtPeriodHousing,
    otherEndAtPeriodHousing: dogForm.value?.otherEndAtPeriodHousing,
  };

  // toaster.info("Données appliquées. Prêt à être enregistrer.");
}
const deepCompare = (obj1, obj2) => {
  if (typeof obj1 !== 'object' || obj1 === null || Array.isArray(obj1)) {
    return obj1 === obj2;
  }

  const obj1Keys = Object.keys(obj1);
  const obj2Keys = Object.keys(obj2);

  if (obj1Keys.length !== obj2Keys.length) {
    return false;
  }

  return obj1Keys.every((key) => {
      if (!obj1[key]) {
        if(undefined === obj2[key]){
          return undefined === obj1[key]
        }

        if(null === obj2[key]){
          return null === obj1[key]
        }

        if('' === obj2[key]){
          return '' === obj1[key]
        }

        if(false === obj2[key]){
          return false === obj1[key]
        }

        if(0 === obj2[key]){
          return 0 === obj1[key]
        }

        return obj1 === obj2;
    }

    return deepCompare(obj1[key], obj2[key])
  });
}
const isDogDirty = () => !deepCompare({...dogForm.value}, {...forms.value.dog})
const isFatherDirty = () => !deepCompare({...dogFatherForm.value}, {...forms.value.father})
const isMotherDirty = () => !deepCompare({...dogMotherForm.value}, {...forms.value.mother})
const isMasterDirty = () => !deepCompare({...masterForm.value}, {...forms.value.master})
    || !deepCompare({...ownerForm.value}, {...forms.value.owner})
const isHoldingDirty = () => !deepCompare({...holdingForm.value}, {...forms.value.holding})
const isDirty = computed(() => {
  if (!loaded.value || loading.value) {
    return false;
  }

  return isDogDirty() || isFatherDirty() || isMotherDirty() || isMasterDirty() || isHoldingDirty();
})
const changeOwner = (id) => {
  const temp = dogMasterList.value.find(el => el.id === id);

  ownerForm.value = getOwnerFormFromPayload(temp)
  if (masterIsOwnerDog.value) {
    masterForm.value = {
      ...masterForm.value,
      ...ownerForm.value,
    }
  }

  forms.value = {
    ...forms.value,
    owner: {...ownerForm.value},
  }

  if (temp) {
    ownerIsOwnerExploitation.value = temp.id === census.value.exploitation.owner.id;
    ownerSocialReason.value = temp.companyname ? "social" : "particular";
  } else {
    ownerIsOwnerExploitation.value = false;
    ownerSocialReason.value = "social";
  }
}
const changeMaster = (id) => {
  const temp = dogMasterList.value.find(el => el.id === id);

  masterForm.value = getMasterFormFromPayload(temp);
  forms.value = {
    ...forms.value,
    master: {...masterForm.value},
  }

  if (temp) {
    masterIsOwnerDog.value = dog.value.master?.id === dog.value.owner?.id
    masterIsOwnerExploitation.value = temp.id === census.value.exploitation.owner.id;
    masterSocialReason.value = temp.companyname ? "social" : "particular";
  } else {
    masterIsOwnerExploitation.value = false;
    masterSocialReason.value = "particular";
  }
}
const handleCancelQuit = () => {
  store.quittingCensusDetails = false;
  store.quittingCensusDetailsTo = null;
}
const checkIfCensusIsLaunched = () => {
  if (
      census.value?.status === 'scheduled'
      && (isUserAdmin.value || isSameUser.value)
      && isDogAboveEighteen.value
  ) {
    showLaunchCensusModal.value = true;
  }
}
const launchCensus = async () => {
  try {
    loading.value = true;
    showLaunchCensusModal.value = false;
    await CensusService.updateCensus(
        census.value.id,
        {status: "running"},
        controller
    );

    await fetchAll();
    loading.value = false;
  } catch (error) {
  }
};

watch(() => props.dogId, async () => {
  resetAll();
  store.currentCensus.step = 4

  loading.value = true;
  loaded.value = false;

  await fetchAll();
  checkIfCensusIsLaunched();

  loaded.value = true;
  loading.value = false;
});
watch(() => ownerId.value, async () => {
  changeOwner(ownerId.value)
});
watch(() => masterId.value, async () => {
  changeMaster(masterId.value)
});
watch(() => technicalFollowUp.value, () => {
  if (!technicalFollowUp.value) {
    dogForm.value.hasGoodImpregnateHerdBfrTwoMounth = null;
    dogForm.value.hasGoodFamiliarizationHumansBfrTwoMounths = null;
    dogForm.value.hasGoodFamiliarizationHumansAfterTwoMounths = null;
    dogForm.value.wasEasySetupPuppy = null;
    dogForm.value.isClingyWithHerd = null;
  }
});

onMounted(async () => {
  resetAll();
  store.currentCensus.step = 4
  loading.value = true;
  loaded.value = false;

  await fetchAll();
  checkIfCensusIsLaunched();

  loaded.value = true;
  loading.value = false;
})

router.beforeEach((to, from) => {
  if (
      store.currentCensus.step === 4
      && !to.path.includes('/census/' + route.params.censusId)
  ) {
    store.quittingCensusDetailsTo = to.path;
    store.quittingCensusDetails = true;

    return false;
  }

  if (
      store.currentCensus.step === 4
      && loaded.value
      && census.value.status !== 'completed'
      && isDirty.value
      && from.name === 'census_details_page'
      && from.href !== to.href
  ) {
    if (
        from.params.censusId !== to.params.censusId
        || from.params.dogId !== to.params.dogId
    ) {
      store.quittingCensusDetailsTo = to.path;
      store.leavingCensusDetail = true;

      return false;
    }
  }
})

onBeforeUnmount(() => {
  resetAll();
})

const scrollToFirstInvalidInput = () => {
  nextTick(() => {
    const element = document.querySelector('.is-invalid');
    if (element) {
      element.scrollIntoView({behavior: 'smooth', block: "center", inline: "center"});
      element.focus();
    }
  });
};
</script>

<template>
  <LoaderList v-if="loading"/>

  <div v-if="!loading && loaded && census" class="tw-flex tw-flex-col tw-space-y-10 tw-mt-5">
    <div style="z-index: 4"
         class="tw-flex tw-flex-col tw-space-x-5 tw-sticky tw-top-0 tw-bg-white tw-space-y-5 tw-pb-2">
      <div class="tw-flex tw-space-x-5">
        <div
            class="tw-bg-primary tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-w-10 tw-h-10 tw-cursor-pointer"
            @click="handleBack"
        >
          <img alt="back" :src="arrowBack"/>
        </div>

        <div
            ref="navigationRef"
            class="tw-relative tw-flex-col tw-flex-1"
        >
          <div
              @click="navigationOpen = !navigationOpen"
              class="tw-bg-primary tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-h-10 tw-cursor-pointer tw-px-3"
          >
            <div class="tw-text-white tw-text-sm tw-font-bold tw-flex-1">{{ dog?.name ?? '' }}</div>

            <div class="">
              <img :class="navigationOpen ? 'tw-rotate-180' : 'tw-rotate-0'"
                   class="tw-transform tw-transition-transform tw-ease-in-out tw-duration-200" alt="arrow"
                   :src="arrowDown"/>
            </div>
          </div>

          <transition
              enter-active-class="tw-transform tw-transition tw-duration-500 ease-in"
              enter-class="-tw-translate-y-1/2 tw-scale-y-0 tw-opacity-0"
              enter-to-class="translate-y-0 tw-scale-y-100 tw-opacity-100"
              leave-active-class="tw-transform tw-transition tw-duration-300 ease-in"
              leave-class="translate-y-0 tw-scale-y-100 tw-opacity-100"
              leave-to-class="-tw-translate-y-1/2 tw-scale-y-0 tw-opacity-0"
          >
            <div
                v-if="navigationOpen"
                class="tw-bg-primary tw-z-50 tw-absolute tw-rounded-b-lg tw-shadow tw-flex tw-flex-col tw-divide-y tw-divide-y-1 tw-divide-red-500 tw-mt-0.5 tw-w-full">
              <div
                  v-for="item in (census?.dogs ?? []).sort((a, b) => a.name.localeCompare(b.name))"
                  :key="item.id"
                  @click="handleDogClick(item.id)"
                  class="tw-flex tw-justify-between tw-items-center tw-py-3 tw-px-5 tw-text-white"
              >
                <div class="tw-no-underline	">{{ item.name }}</div>

                <svg
                    v-if="dog.id === item.id"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="tw-w-6 tw-h-6">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                </svg>
              </div>
            </div>
          </transition>
        </div>

        <div
            @click="handleCurrentTabChange('holding')"
            :class="currentTab === 'holding' ? 'tw-bg-holding tw-text-white' : 'tw-bg-gray-200 tw-text-gray-500'"
            class="tw-rounded-xl tw-flex tw-items-center tw-justify-center tw-h-10 tw-cursor-pointer tw-relative tw-px-3 tw-space-x-3"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14 7C14 7.4813 13.6533 7.875 13.2222 7.875H12.4443V13.125C12.4443 13.6082 12.096 14 11.6665 14H9.33343C8.90385 14 8.55565 13.6082 8.55565 13.125V10.0623C8.55565 9.82073 8.38158 9.62484 8.16681 9.62484H5.83362C5.61886 9.62484 5.44474 9.82073 5.44474 10.0623V13.125C5.44474 13.6082 5.09653 14 4.66696 14H2.33389C1.90434 14 1.55611 13.6082 1.55611 13.125V7.875H0.777778C0.345674 7.875 2.43056e-05 7.48007 0 7C0 6.75248 0.0933819 6.51066 0.265465 6.34118L6.48769 0.216617C6.65693 0.049875 6.85069 0 7 0C7.18285 0 7.36573 0.0722148 7.51231 0.216617L13.7345 6.34118C13.9066 6.51066 14 6.75248 14 7Z"></path>
          </svg>

          <span class="tw-uppercase tw-hidden md:tw-inline">Exploitation</span>
        </div>
      </div>

      <div class="tw-flex tw-justify-center tw-space-x-5">
        <div
            @click="handleCurrentTabChange('dog')"
            :class="currentTab === 'dog' ? 'tw-bg-dog tw-text-white' : 'tw-bg-gray-200 tw-text-gray-500'"
            class="tw-px-3 tw-py-2 tw-rounded-full tw-space-x-3 tw-cursor-pointer"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.24308 6.12506L10.8889 7.58795V13.5625C10.8889 13.8052 10.7158 14 10.5001 14H8.9445C8.72879 14 8.55561 13.8052 8.55561 13.5625V10.5H4.66672V13.5625C4.66672 13.8052 4.49354 14 4.27783 14H2.72228C2.50656 14 2.33339 13.8052 2.33339 13.5625V7.71442C1.43104 7.35211 0.777832 6.38825 0.777832 5.25006C0.777832 4.76813 1.12722 4.37506 1.55561 4.37506C1.984 4.37506 2.33339 4.76813 2.33339 5.25006C2.33339 5.732 2.68278 6.12506 3.11117 6.12506H7.24308ZM13.2223 3.06259V3.93759C13.2223 4.90485 12.5265 5.68756 11.6667 5.68756H10.8889V6.66168L7.77783 5.41071V1.31259C7.77783 0.922944 8.1971 0.72812 8.44016 1.00497L9.10552 1.75009H10.4089C10.6732 1.75009 10.9862 1.96542 11.1047 2.23544L11.2778 2.62509H12.8334C13.0491 2.62509 13.2223 2.81992 13.2223 3.06259ZM10.5001 3.06259C10.5001 2.81992 10.3269 2.62509 10.1112 2.62509C9.89545 2.62509 9.72228 2.81992 9.72228 3.06259C9.72228 3.30527 9.89545 3.50009 10.1112 3.50009C10.3269 3.50009 10.5001 3.30527 10.5001 3.06259Z"
            ></path>
          </svg>

          <span class="tw-uppercase tw-text-xs md:tw-text-base">CHIEN</span>
        </div>

        <div
            @click="handleCurrentTabChange('master')"
            :class="currentTab === 'master' ? 'tw-bg-master tw-text-white' : 'tw-bg-gray-200 tw-text-gray-500'"
            class="tw-px-3 tw-py-2 tw-rounded-full tw-space-x-3 tw-cursor-pointer"
        >
          <svg width="12" height="12" viewBox="0 0 12 12" fill="currentColor"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 6.75C7.86405 6.75 9.375 5.23891 9.375 3.375C9.375 1.51109 7.86405 0 6 0C4.13618 0 2.625 1.51109 2.625 3.375C2.625 5.23891 4.13618 6.75 6 6.75ZM8.24995 7.5H3.75005C1.67904 7.5 0 9.1789 0 11.25C0 11.6642 0.335859 12 0.749953 12H11.25C11.6641 12 12 11.6642 12 11.25C12 9.1789 10.3211 7.5 8.24995 7.5Z"></path>
          </svg>

          <span class="tw-uppercase tw-text-xs md:tw-text-base">Propriétaire / Maître</span>
        </div>
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-space-y-10">
      <div
          v-if="RecensementService.getRateCompletion(census) === '100' &&
        census.status !== 'completed'
      "
          class="census-validation-msg"
      >
        Le recensement est prêt à être validé
      </div>

      <CensusDog
          @submit="submitDog"
          v-show="loaded && !loading && currentTab === 'dog'"
          :id="dog.id"
          :census="census"
          v-model:dog="dogForm"
          v-model:dog-father="dogFatherForm"
          v-model:dog-mother="dogMotherForm"
          v-model:spec-nb-persons="specNbPersons"
          v-model:specie-other="specieOther"
          v-model:no-father-info="noFatherInfo"
          v-model:no-mother-info="noMotherInfo"
          v-model:technical-follow-up="technicalFollowUp"
          :dog-validator="dogValidator"
          :dog-father-validator="dogFatherValidator"
          :dog-mother-validator="dogMotherValidator"
          :read-only="readOnly"
          :disabled="disabled"
          :is-dog-above-eighteen="isDogAboveEighteen"
          :is-identity-editable="isUserAdmin || isSameUser"
      />

      <CensusMaster
          ref="masterRef"
          v-show="loaded && !loading && currentTab === 'master'"
          v-model:master="masterForm"
          v-model:owner="ownerForm"
          v-model:dog="dogForm"
          v-model:master-is-owner-dog="masterIsOwnerDog"
          v-model:owner-is-owner-exploitation="ownerIsOwnerExploitation"
          v-model:master-is-owner-exploitation="masterIsOwnerExploitation"
          v-model:master-id="masterId"
          v-model:owner-id="ownerId"
          v-model:owner-social-reason="ownerSocialReason"
          v-model:master-social-reason="masterSocialReason"
          :census="census"
          :read-only="readOnly"
          :disabled="disabled"
          :master-validator="masterValidator"
          :owner-validator="ownerValidator"
          :dog-master-list="dogMasterList"
          :dog-master-entries="dogMasterEntries"
      />

      <CensusHolding
          v-show="loaded && !loading && currentTab === 'holding'"
          v-model="holdingForm"
          :validator="holdingValidator"
          :read-only="readOnly"
          :current-owner="census.dogs?.find(item => item.ownerIsOwnerExploitation && item.owner?.id)?.owner"
          :disabled="disabled"
      />

      <div v-if="census && census.person">
        <p
            v-if="!isSameUser && isUserAdmin"
            class="tw-text-gray-600_ tw-text-xl tw-font-semibold tw-text-center"
        >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}</p>

        <p
            v-else-if="readOnly"
            class="tw-text-error tw-text-xl tw-font-semibold tw-text-center"
        >Fiche créée par {{ census.person.firstname }} {{ census.person.lastname }}, modification non autorisée</p>

        <div class="buttons"
             v-if="(isSameUser && census.status !=='completed') || isUserAdmin"
        >
          <Button
              v-if="!props.disabled"
              class="search-btn"
              :disabled="loading || !isDirty"
              @on-click="submit()"
          >
            ENREGISTRER LES INFORMATIONS
          </Button>

          <Button
              v-if="isDogAboveEighteen && !props.disabled && census.status !== 'completed'"
              class="search-btn"
              :disabled="loading"
              @click="validate"
          >
            VALIDER LE CHIEN
          </Button>
        </div>
      </div>


      <span v-if="error" class="error">{{ error }}</span>

      <div v-if="census && census.dogs && !loading && census.dogs.length === 0">Aucun chien</div>
    </div>
  </div>

  <teleport to="body">
    <transition name="bounce">
      <div v-if="store.leavingCensusDetail"
           class="tw-inset-0 tw-w-full tw-h-full tw-fixed tw-z-10 tw-flex tw-items-center tw-justify-center">
        <div style="background-color: rgb(0 0 0 / 0.2)"  class="tw-absolute tw-h-full tw-w-full"></div>

        <div class="tw-z-10 tw-p-10 tw-bg-white tw-rounded-lg tw-shadow-xl tw-flex tw-flex-col tw-space-y-5">
          <h2 class="tw-text-sm md:tw-text-lg lg:tw-text-2xl tw-font-semibold lg:tw-font-bold">
            Voulez-vous enregistrer les modifications avant de continuer?
          </h2>

          <span class="tw-text-xs md:tw-text-lg lg:tw-text-xl">Les modifications non enregistrées seront perdues</span>

          <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-5">
            <Button type="secondary" @on-click="leave">Ne pas enregistrer</Button>

            <Button type="green" @on-click="saveAndLeave">Enregistrer</Button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="bounce">
      <div v-if="showLaunchCensusModal"
           class="tw-inset-0 tw-w-full tw-h-full tw-fixed tw-z-10 tw-flex tw-items-center tw-justify-center">
        <div class="tw-bg-black/20 tw-absolute tw-h-full tw-w-full"></div>

        <div class="tw-z-10 tw-p-10 tw-bg-white tw-rounded-lg tw-shadow-xl tw-flex tw-flex-col tw-space-y-5">
          <h2 class="tw-text-sm md:tw-text-lg lg:tw-text-2xl tw-font-semibold lg:tw-font-bold">
            Souhaitez-vous lancer le recensement?
          </h2>

          <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-5">
            <Button type="secondary" @on-click="showLaunchCensusModal = false">Consulter</Button>

            <Button type="green" @on-click="launchCensus">Lancer</Button>
          </div>
        </div>
      </div>
    </transition>

    <transition name="bounce">
      <div
          v-if="store.quittingCensusDetails"
          class="tw-fixed tw-inset-0 tw-bg-white tw-bg-opacity-50 tw-z-50 tw-flex tw-items-center tw-justify-center"
      >

        <div class="tw-rounded-lg tw-p-10 tw-bg-white tw-shadow-lg">
          <h1 class="tw-text-xl">
            Un recensement est en {{ census.status === 'completed' || readOnly ? 'lecture' : 'cours' }}, êtes-vous sûr
            de vouloir quitter cette page?
          </h1>
          <div class="tw-flex tw-justify-center tw-w-full tw-space-x-5">
            <button
                type="button"
                @click="handleSaveAndQuit"
                class="tw-bg-error tw-text-white tw-py-3 tw-border-none tw-uppercase tw-w-full tw-rounded-2xl tw-cursor-pointer tw-font-medium"
            >
              Sortir<span v-if="census.status === 'completed' || !readOnly" class="tw-hidden lg:tw-inline"> et enregistrer</span>
            </button>

            <button
                type="button"
                @click="handleCancelQuit"
                class="tw-py-3 tw-border-none tw-uppercase tw-w-full tw-rounded-2xl tw-cursor-pointer tw-font-medium"
            >
              Annuler<span class="tw-hidden lg:tw-inline"> et rester</span>
            </button>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style scoped lang="scss">
@import "../../../assets/css/_variables.scss";

.buttons {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.search-btn {
  width: 100%;
  text-decoration: none;
  padding: 0.5em 1em;
  text-align: center;
  background-color: $green;
  color: white;
  border-radius: 14px;
  margin-top: 1em !important;
  margin-bottom: 1em;
}

.bounce-enter-active {
  animation: bounce-in .15s;
}

.bounce-leave-active {
  animation: bounce-in .15s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media screen and (max-width: 900px) {
  .dropdown {
    min-width: 90%;
    left: 2.5%;
    padding: 0.5em;
  }
}
</style>